import {FunctionComponent} from "react";
import {Alert, Button, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {sirdataGtmServerSideConfigureDnsUrl} from "../../utils/helper";

type ModalHowConfigureDnsProps = {
    active: boolean;
    onClose: () => void;
}

const ModalHowConfigureDns: FunctionComponent<ModalHowConfigureDnsProps> = ({active, onClose}) => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <ModalNew active={active} onClose={onClose} cssClass="modal-configure-dns">
            <ModalHeader>
                <ModalHeaderTitle title={textContainer("configure_dns.title")}/>
            </ModalHeader>
            <ModalContent>
                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.MEDIUM}>
                    <span className="h3">{textContainer("configure_dns.basic.title")}</span>
                    <ul>
                        {(textContainer("configure_dns.basic.steps", {returnObjects: true}) as string[]).map((it) =>
                            <li key={it}>• <span dangerouslySetInnerHTML={{__html: it}}/></li>
                        )}
                    </ul>
                    <Alert text={textContainer("configure_dns.alert", {url: sirdataGtmServerSideConfigureDnsUrl})}/>
                </FlexContent>
            </ModalContent>
            <ModalActions>
                <Button onClick={onClose}>{textContainer("configure_dns.confirm")}</Button>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalHowConfigureDns;
