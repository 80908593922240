export enum ContainerField {
    ID = "id",
    NAME = "name",
    CONTAINER_CONFIG_ID = "container_config_id",
    DEFAULT_PROPERTIES = "default_properties",
    OVERRIDE_PROPERTIES_PER_HOST_LIST = "override_properties_per_host_list",
    INSTANCE_HOSTS = "instance_hosts",
    INSTANCE_STATUS = "instance_status",
    STATUS = "status",
    HOST_DETAILS = "host_details",
    DEPLOYMENT_DESTINATION = "deployment_destination",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts",
}
