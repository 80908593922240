import {ApiModel} from "../../../common/api/model/ApiModel";
import {StatHitPerConf} from "./StatHitsPerConf";

export class StatHit extends ApiModel {
    date: string = "";
    total_hits: number = 0;
    hit_per_conf: StatHitPerConf[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "hit_per_conf") {
                    (o[key] as StatHitPerConf[]).forEach((obj) => {
                        const it = new StatHitPerConf(obj);
                        this.hit_per_conf.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }
}
