import {Rest} from "../../common/api/rest/Rest";
import {StatHit} from "../model/stat/StatHit";

export class RestStat extends Rest {
    rootPath = "/gtm-helper-api/stats/conf";

    getDailyHits(start_date: string, end_date: string, containerId?: number): Promise<StatHit[]> {
        return this._client.get(`${this.rootPath}`, StatHit, {queryParams: {conf_id: containerId, start_date, end_date}}) as Promise<StatHit[]>;
    }
}
