import {ApiModel} from "../../../common/api/model/ApiModel";
import {isValidDomain} from "../../../utils/helper";
import {ContainerHostDetailsStatus} from "./ContainerHostDetailsStatus";

export class ContainerHostDetails extends ApiModel {
    fqdn: string = "";
    domain: string = "";
    subdomain: string = "";
    status: ContainerHostDetailsStatus = ContainerHostDetailsStatus.NOT_FOUND;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getHostname(): string {
        return `${this.subdomain}.${this.domain}`;
    }

    hasHostnameFilled(): boolean {
        return (!!this.subdomain && !!this.domain);
    }

    hasValidHostname(): boolean {
        return isValidDomain(this.domain) && isValidDomain(this.getHostname());
    }
}
