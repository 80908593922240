import {MenuItem} from "@sirdata/ui-lib";
import {pathHome} from "../common/utils/constants";
import {sirdataGtmServerSideDocumentationUrl} from "./helper";
import {pathContainerList} from "./constants";

export enum Menu {
    HOME = "home",
    CONTAINERS = "containers",
    DOCUMENTATION = "documentation"
}

export const MENU: MenuItem[] = [
    {label: Menu.HOME, path: pathHome, icon: {name: "home"}},
    {label: Menu.CONTAINERS, path: pathContainerList, icon: {name: "dns"}},
    {label: Menu.DOCUMENTATION, path: sirdataGtmServerSideDocumentationUrl, icon: {name: "description"}, externalUrl: true}
];
