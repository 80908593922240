import moment from "moment";
import {Formatter} from "@sirdata/ui-lib";

const DATE_API_FORMAT = "YYYY-MM-DD";

export enum StatPeriod {
    LAST_7_DAYS = "LAST_7_DAYS",
    LAST_30_DAYS = "LAST_30_DAYS",
    MONTH = "MONTH",
    LAST_MONTH = "LAST_MONTH",
    LAST_3_MONTHS = "LAST_3_MONTHS",
    YEAR = "YEAR",
    CUSTOM = "CUSTOM"
}

export type StatPeriodItemFilter = {
    start: string;
    end: string;
}

export type StatPeriodItem = {
    type: StatPeriod;
    filter: StatPeriodItemFilter;
}

export const STAT_PERIODS: StatPeriodItem[] = [
    {
        type: StatPeriod.LAST_7_DAYS,
        filter: {
            start: Formatter.formatDate(moment().subtract(7, "day"), DATE_API_FORMAT),
            end: Formatter.formatDate(moment(), DATE_API_FORMAT)
        }
    },
    {
        type: StatPeriod.LAST_30_DAYS,
        filter: {
            start: Formatter.formatDate(moment().subtract(30, "day"), DATE_API_FORMAT),
            end: Formatter.formatDate(moment(), DATE_API_FORMAT)
        }
    },
    {
        type: StatPeriod.MONTH,
        filter: {
            start: Formatter.formatDate(moment().startOf("month"), DATE_API_FORMAT),
            end: Formatter.formatDate(moment().endOf("month"), DATE_API_FORMAT)
        }
    },
    {
        type: StatPeriod.LAST_MONTH,
        filter: {
            start: Formatter.formatDate(moment().subtract(1, "months").startOf("month"), DATE_API_FORMAT),
            end: Formatter.formatDate(moment().subtract(1, "months").endOf("month"), DATE_API_FORMAT)
        }
    },
    {
        type: StatPeriod.LAST_3_MONTHS,
        filter: {
            start: Formatter.formatDate(moment().subtract(2, "months").startOf("month"), DATE_API_FORMAT),
            end: Formatter.formatDate(moment().endOf("month"), DATE_API_FORMAT)
        }
    },
    {
        type: StatPeriod.YEAR,
        filter: {
            start: Formatter.formatDate(moment().startOf("year"), DATE_API_FORMAT),
            end: Formatter.formatDate(moment().endOf("year"), DATE_API_FORMAT)
        }
    }
];

export const defaultStatPeriod = STAT_PERIODS[1];

export function getStatPeriodFilter(type: StatPeriod): StatPeriodItemFilter {
    return STAT_PERIODS.find((it) => it.type === type)?.filter || defaultStatPeriod.filter;
}
