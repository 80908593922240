import {Icon, IconProps} from "@sirdata/ui-lib";

type IconTitleProps = {
    icon: IconProps;
    title: string;
};

const IconTitle: React.FC<IconTitleProps> = ({icon, title}) => {
    return (
        <div className="h3 icon-title">
            <Icon {...icon}/>
            <span>{title}</span>
        </div>
    );
};

export default IconTitle;
