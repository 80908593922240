import React from "react";
import {Box, BoxRadius, BoxSpacing, Button, ButtonStyle, Color, Paragraph} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {IconTitle} from "..";
import {TranslationPortalFile} from "../../../utils/constants";
import {ModalContactUiEvent} from "../../../common/component/modal/ModalContact";
import {UIEventManager} from "../../../common/utils/UIEventManager";

const CardDeploy: React.FC = () => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <Box radius={BoxRadius.MD} spacing={BoxSpacing.LARGE} cssClass="card-deploy">
            <div>
                <IconTitle icon={{name: "alt_route", colorIcon: Color.CYAN}} title={textContainer("card.deploy.title")}/>
                <Paragraph withSpacing>
                    <span dangerouslySetInnerHTML={{__html: textContainer("card.deploy.description")}}/>
                </Paragraph>
            </div>
            <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => UIEventManager.emit(ModalContactUiEvent, {})}>
                {textContainer("card.deploy.action")}
            </Button>
        </Box>
    );
};

export default CardDeploy;
