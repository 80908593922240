export enum ContainerSortType {
    ID = "ID",
    NAME = "NAME",
    RECENCY = "RECENCY"
}

export type ContainerSortTypeItem = {
    type: ContainerSortType;
    field: string;
}

export const CONTAINER_SORT_TYPES: ContainerSortTypeItem[] = [
    {type: ContainerSortType.RECENCY, field: "creation_ts"},
    {type: ContainerSortType.NAME, field: "name"},
    {type: ContainerSortType.ID, field: "id"}
];

export const defaultContainerSortTypeItem = CONTAINER_SORT_TYPES[0];
