import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader} from "../common/component/snippet";
import {MainContentPageHeader, MainContentStyle, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";

type LegalNoticeSection = {
    title: string;
    paragraphs: { rows: string[] }[];
}

function LegalNotice() {
    const {t: textLegalNotice} = useTranslation(TranslationPortalFile.LEGAL_NOTICE);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textLegalNotice("title")}/>
                {(textLegalNotice("sections", {returnObjects: true}) as LegalNoticeSection[]).map(({title, paragraphs}) =>
                    <React.Fragment key={title}>
                        <h2>{title}</h2>
                        {paragraphs.map(({rows}, rowIndex) => {
                            const paragraphKey = `p-${rowIndex}`;
                            return (
                                <Paragraph key={paragraphKey} alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                                    {rows.map((row, index) =>
                                        <span key={`${paragraphKey}.${index.toString()}`}>{index !== 0 && <br/>}{row}</span>
                                    )}
                                </Paragraph>
                            );
                        })}
                    </React.Fragment>
                )}
            </MainContent>
        </Wrapper>
    );
}

export default LegalNotice;
