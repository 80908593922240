import {FunctionComponent} from "react";
import {Tag, TagSize, TagStyle, Tooltip} from "@sirdata/ui-lib";
import {Container} from "../../../api/model/container/Container";

type ContainerTableRowHostnameProps = {
    container: Container;
}

const ContainerTableRowHostname: FunctionComponent<ContainerTableRowHostnameProps> = ({container}) => {
    const [firstHost, ...hosts] = container.instance_hosts;

    return (
        <>
            {firstHost || ""}
            {hosts.length ?
                <Tooltip text={hosts.join("<br/>")}>
                    <Tag label={`+${hosts.length}`} size={TagSize.XSMALL} style={TagStyle.DEFAULT_OCEAN}/>
                </Tooltip> : undefined
            }
        </>
    );
};

export default ContainerTableRowHostname;
