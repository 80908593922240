import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {MainContent, Wrapper} from "../common/component/widget";
import {LayoutRows, MainContentPageHeader, MainContentStyle, Paragraph, ParagraphAlignment, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {Formatter} from "../common/utils/Formatter";
import {MainHeader} from "../common/component/snippet";

type TermsAndConditionsSection = {
    title: string;
    paragraphs: string[];
    noBullet?: boolean;
};

type DataProcessingAgreementSection = {
    title: string;
    paragraphs: string[];
    isSubtitle?: boolean;
};

function TermsAndConditions() {
    const {t: textTermsAndConditions} = useTranslation(TranslationPortalFile.TERMS_AND_CONDITIONS);
    const {t: textDataProcessingAgreement} = useTranslation(TranslationPortalFile.DATA_PROCESSING_AGREEMENT);

    const PRICING_ITEMS = [
        {from: 0, to: 750000, price: 50},
        {from: 750001, to: 2000000, price: 100},
        {from: 2000001, to: 4000000, price: 190},
        {from: 4000001, to: 6000000, price: 270},
        {from: 6000001, to: 8000000, price: 330},
        {from: 8000001, to: 10000000, price: 380},
        {from: 10000001, to: 15000000, price: 420},
        {from: 15000000, price: 500, priceByHit: 0.00002}
    ];

    const PRICING_CMP_ITEMS = [
        {type: textTermsAndConditions("set_up.cmp_items.sirdata_cmp"), price: 0},
        {type: textTermsAndConditions("set_up.cmp_items.tcf_cmp"), price: 500},
        {type: textTermsAndConditions("set_up.cmp_items.non_tcf_cmp"), price: 1500}
    ];

    const NUMBER_FORMAT = "0,0.[00]";

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textTermsAndConditions("title")}/>
                <LayoutRows>
                    <div>
                        {(textTermsAndConditions("sections", {returnObjects: true}) as TermsAndConditionsSection[]).map(({title, paragraphs}, sectionIndex) => {
                            const sectionKey = `section${sectionIndex}`;
                            return (
                                <React.Fragment key={sectionKey}>
                                    {title && <h2>{`${sectionIndex}. `}{title}</h2>}
                                    {paragraphs.map((item, itemIndex) =>
                                        item === "[PRICING_TABLE]" ?
                                            <Table
                                                key={`${sectionKey}-pricingTable${itemIndex.toString()}`}
                                                columns={[
                                                    {label: textTermsAndConditions("pricing.monthly_hits"), width: 50},
                                                    {label: textTermsAndConditions("pricing.price_in_euros"), width: 50}
                                                ]}
                                            >
                                                {PRICING_ITEMS.map(({from, to, price, priceByHit}, rowIndex) =>
                                                    rowIndex === PRICING_ITEMS.length - 1 ?
                                                        <TableRow key={price}>
                                                            <TableColumn>{textTermsAndConditions("pricing.hit_beyond", {from: Formatter.formatNumber(from, NUMBER_FORMAT)})}</TableColumn>
                                                            <TableColumn>{textTermsAndConditions("pricing.price_beyond", {from: Formatter.formatNumber(from, NUMBER_FORMAT), price: Formatter.formatNumber(price, NUMBER_FORMAT), priceByHit: priceByHit})}</TableColumn>
                                                        </TableRow> :
                                                        <TableRow key={price}>
                                                            <TableColumn>{textTermsAndConditions("pricing.hit_range", {from: Formatter.formatNumber(from, NUMBER_FORMAT), to: Formatter.formatNumber(to, NUMBER_FORMAT)})}</TableColumn>
                                                            <TableColumn>{textTermsAndConditions("pricing.price", {price: price})}</TableColumn>
                                                        </TableRow>
                                                )}
                                            </Table> :
                                            item === "[PRICING_CMP_TABLE]" ?
                                                <Table
                                                    key={`${sectionKey}-pricingCmpTable${itemIndex.toString()}`}
                                                    columns={[
                                                        {label: textTermsAndConditions("set_up.cmp_title"), width: 50},
                                                        {label: textTermsAndConditions("set_up.set_up_title"), width: 50}
                                                    ]}
                                                >
                                                    {PRICING_CMP_ITEMS.map(({price, type}) =>
                                                        <TableRow key={price}>
                                                            <TableColumn>{type}</TableColumn>
                                                            <TableColumn>{textTermsAndConditions("pricing.price", {price: price})}</TableColumn>
                                                        </TableRow>
                                                    )}
                                                </Table> :
                                                <Paragraph key={`${sectionKey}-p${itemIndex.toString()}`} alignment={ParagraphAlignment.JUSTIFY} withSpacing><span dangerouslySetInnerHTML={{__html: item}}/></Paragraph>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <hr/>
                    {["dpa", "dpa_appendix1", "dpa_appendix2"].map((label) =>
                        <div key={label}>
                            <div className="h1">{textDataProcessingAgreement(`title_${label}`)}</div>
                            {(textDataProcessingAgreement(label, {returnObjects: true}) as DataProcessingAgreementSection[]).map(({title, paragraphs}, sectionIndex) => {
                                const sectionKey = `${label}-section${sectionIndex}`;
                                return (
                                    <div key={sectionKey}>
                                        {title && <h2>{title}</h2>}
                                        {paragraphs.map((item, itemIndex) =>
                                            <Paragraph key={`${sectionKey}-p${itemIndex.toString()}`} alignment={ParagraphAlignment.JUSTIFY} withSpacing><span dangerouslySetInnerHTML={{__html: item}}/></Paragraph>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default TermsAndConditions;
