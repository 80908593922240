import React from "react";
import {Paragraph, Tag, TagSize, TagStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

export type HelpCarouselSlideProps = {
    img: string;
    content: string;
    step: number;
    totalSteps: number;
}

const HelpCarouselSlide: React.FC<HelpCarouselSlideProps> = ({img, content, step, totalSteps}) => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <div className="help-carousel-slide">
            <div className="help-carousel-slide__content">
                <Tag label={textContainer("tag.step_state", {step, totalSteps})} style={TagStyle.DEFAULT_OCEAN} size={TagSize.XSMALL}/>
                <Paragraph cssClass="help-carousel-slide__content__text"><span dangerouslySetInnerHTML={{__html: content}}/></Paragraph>
            </div>
            <div className="help-carousel-slide__image">
                <img src={img} alt=""/>
            </div>
        </div>
    );
};

export default HelpCarouselSlide;
