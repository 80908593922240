import {ApiModel} from "../../../../common/api/model/ApiModel";
import {defaultContainerSortTypeItem} from "./ContainerSortType";
import {defaultSortOrderItem} from "./ContainerSortOrder";

export class ContainerSearchQuery extends ApiModel {
    sort_by: string = defaultContainerSortTypeItem.field;
    sort_order: string = defaultSortOrderItem.field;
    search: string = "";
    size: number = 500;
    page: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            search: this.search,
            size: this.size,
            page: this.page
        };
    }
}
