import {ApiModel} from "../../../common/api/model/ApiModel";

export class StatHitPerConf extends ApiModel {
    conf_id: string = "";
    total_hits: number = 0;
    hit_per_type: {FORWARD: number; ANALYTICS: number} = {FORWARD: 0, ANALYTICS: 0};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
