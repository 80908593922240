import {AlertSeverity, ButtonLinkCancel, ButtonSave, Form, FormLayoutButtons, FormLayoutRows, ModalContent, ModalNew} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";

import {Container} from "../../api/model/container/Container";
import {ContainerErrorCode} from "../../api/model/container/ContainerErrorCode";
import {ContainerHostDetails} from "../../api/model/container/ContainerHostDetails";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {FormContainerHostname} from "../snippet";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

type ModalAddContainerHostnameProps = {
    active: boolean;
    container: Container;
    onEdit: (container: Container) => void;
    onClose: () => void;
}

const ModalAddContainerHostname: FunctionComponent<ModalAddContainerHostnameProps> = ({active, container, onEdit, onClose}) => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);
    const [hostDetails, setHostDetails] = useState<ContainerHostDetails>(new ContainerHostDetails());
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSaveEditHosts = async (e: FormEvent) => {
        e.preventDefault();

        try {
            setSubmitting(true);
            const newContainer = new Container(container);
            newContainer.instance_hosts.push(hostDetails.getHostname());
            const updatedContainer = await session.restContainer.update(newContainer);
            UIEventManager.alert({text: textContainer("success.update"), severity: AlertSeverity.SUCCESS, isClosable: true});
            onEdit(updatedContainer);
            handleClose();
        } catch(e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_UPDATE_CONF}`, {hostname: hostDetails.getHostname(), domain: hostDetails.domain}),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        } finally {
            setSubmitting(false);
        }
    };

    const handleClose = () => {
        setHostDetails(new ContainerHostDetails());
        onClose();
    };

    return (
        <ModalNew active={active}>
            <ModalContent>
                <FormLayoutRows>
                    <Form onSubmit={handleSaveEditHosts}>
                        <FormLayoutRows>
                            <FormContainerHostname hostDetails={hostDetails} onChange={setHostDetails}/>
                            <FormLayoutButtons>
                                <ButtonLinkCancel onClick={handleClose}/>
                                <ButtonSave disabled={!hostDetails.hasValidHostname()} loading={isSubmitting}/>
                            </FormLayoutButtons>
                        </FormLayoutRows>
                    </Form>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalAddContainerHostname;
