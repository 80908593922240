import {Rest} from "../../common/api/rest/Rest";
import {CmpConfig} from "../model/cmp/CmpConfig";

export class RestCmpConfig extends Rest {
    rootPath = "/gtm-helper-api/cmp/conf";

    list(): Promise<CmpConfig[]> {
        return this._client.get(this.rootPath, CmpConfig) as Promise<CmpConfig[]>;
    }
}
