import {ApiModel} from "../../../common/api/model/ApiModel";
import {ContainerDeploymentDestination} from "./ContainerDeploymentDestination";
import {ContainerField} from "./ContainerField";
import {ContainerHostDetails} from "./ContainerHostDetails";
import {ContainerInstanceStatus} from "./ContainerInstanceStatus";
import {ContainerOverridePropertiesPerHostList} from "./ContainerOverridePropertiesPerHostList";
import {ContainerProperties} from "./ContainerProperties";
import {ContainerStatus} from "./ContainerStatus";
import {isBase64} from "../../../utils/helper";
import {pathContainerDetails} from "../../../utils/constants";
import {PathHelper} from "../../../common/utils/PathHelper";

export class Container extends ApiModel {
    id: number = 0;
    name: string = "";
    container_config_id: string = "";
    default_properties: ContainerProperties = new ContainerProperties();
    override_properties_per_host_list: ContainerOverridePropertiesPerHostList[] = [];
    instance_hosts: string[] = [];
    status: ContainerStatus = ContainerStatus.ACTIVE;
    helper_active: boolean = false;
    deployment_destination: ContainerDeploymentDestination = ContainerDeploymentDestination.SIRDATA;
    readonly instance_status: ContainerInstanceStatus = ContainerInstanceStatus.OFFLINE;
    readonly host_details: ContainerHostDetails[] = [];
    readonly creation_ts: string = "";
    readonly last_update_ts: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === ContainerField.DEFAULT_PROPERTIES) {
                    this[key] = new ContainerProperties(o[key]);
                } else if (key === ContainerField.OVERRIDE_PROPERTIES_PER_HOST_LIST) {
                    this.override_properties_per_host_list = [];
                    if (o[key]) {
                        (o[key] as ContainerOverridePropertiesPerHostList[]).forEach((obj) => {
                            const it = new ContainerOverridePropertiesPerHostList(obj);
                            this.override_properties_per_host_list.push(it);
                        });
                    }
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            container_config_id: this.container_config_id,
            default_properties: this.default_properties.getJsonParameters(),
            override_properties_per_host_list: this.override_properties_per_host_list.map((it) => it.getJsonParameters()),
            instance_hosts: this.instance_hosts,
            status: this.status,
            helper_active: this.helper_active,
            deployment_destination: this.deployment_destination
        };
    }

    getDetailsRoute(): string {
        return PathHelper.buildPathWithId(pathContainerDetails, this.id);
    }

    hasValidContainerConfigId(): boolean {
        return isBase64(this.container_config_id);
    }
}
