import {routeParamId} from "../common/utils/constants";

export const searchWelcome = "?welcome";

/* ROUTES */
export const pathContainerList = "/containers";
export const pathContainerDetails = pathContainerList + routeParamId;
export const pathContainerCreate = pathContainerList + "/create";
export const pathContainerCreateWelcome = pathContainerCreate + searchWelcome;
export const pathTermsAndConditions = "/terms-and-conditions";

/* TRANSLATIONS */
export enum TranslationPortalFile {
    ACCESS = "access",
    ANALYTICS = "analytics",
    DATA_PROCESSING_AGREEMENT = "data-processing-agreement",
    HOME = "home",
    LEGAL_NOTICE = "legal-notice",
    LOGIN = "login",
    CONTAINER = "container",
    TERMS_AND_CONDITIONS = "terms-and-conditions",
    TRANSLATION = "translation"
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.ACCESS,
    TranslationPortalFile.ANALYTICS,
    TranslationPortalFile.DATA_PROCESSING_AGREEMENT,
    TranslationPortalFile.HOME,
    TranslationPortalFile.LEGAL_NOTICE,
    TranslationPortalFile.LOGIN,
    TranslationPortalFile.CONTAINER,
    TranslationPortalFile.TERMS_AND_CONDITIONS,
    TranslationPortalFile.TRANSLATION
];
