import React from "react";
import {Carousel} from "@sirdata/ui-lib";
import HelpCarouselSlide from "./HelpCarouselSlide";

type HelpCarouselProps = {
    ariaLabel: string;
    slides: {img: string; content: string}[];
}

const HelpCarousel: React.FC<HelpCarouselProps> = ({ariaLabel, slides}) => {
    const totalSlides = slides.length;
    return (
        <Carousel
            ariaLabel={ariaLabel}
            slides={slides.map((slide, index) => (
                <HelpCarouselSlide
                    key={`${ariaLabel}.slide_${index + 1}`}
                    img={slide.img}
                    content={slide.content}
                    step={index + 1}
                    totalSteps={totalSlides}
                />
            ))}
            splideOptions={{
                autoplay: false,
                pagination: false
            }}
            cssClass="help-carousel"
        />
    );
};

export default HelpCarousel;
