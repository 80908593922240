import {FunctionComponent} from "react";
import {Button, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, ModalContent, ModalNew, ModalTitle, Paragraph, SvgRocket} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {TranslationPortalFile} from "../../utils/constants";

type ModalWelcomeProps = {
    active: boolean;
    onClose: () => void;
}

const ModalWelcome: FunctionComponent<ModalWelcomeProps> = ({active, onClose}) => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <ModalNew active={active} cssClass="modal-welcome">
            <ModalContent>
                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.SMALL}>
                    <SvgRocket/>
                    <ModalTitle>
                        {textContainer("welcome.title")}
                    </ModalTitle>
                    <Paragraph>
                        {textContainer("welcome.description")}
                    </Paragraph>
                    <Button onClick={onClose}>{textContainer("welcome.cta")}</Button>
                </FlexContent>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalWelcome;
