export enum ContainerErrorCode {
    CONF_NOT_FOUND = "conf_not_found",
    FAIL_CREATE_CONF = "fail_create_conf",
    FAIL_DECODE_JSON = "fail_decode_json",
    FAIL_DELETE_CONF = "fail_delete_conf",
    FAIL_UPDATE_CONF = "fail_update_conf",
    FAIL_UPDATE_CONF_STATUS = "fail_update_conf_status",
    FAIL_CHECK_FQDN_EXISTS = "fail_check_fqdn_exists",
    FQDN_ALREADY_USED = "fqdn_already_used",
    INVALID_CONF_ID = "invalid_conf_id",
    INVALID_CONTAINER_CONFIG_ID = "invalid_container_config_id",
    INVALID_STATUS = "invalid_status",
    MISSING_HOST = "missing_host",
    NAME_TOO_SHORT = "name_too_short",
    USE_FORBIDDEN_DOMAIN = "use_forbidden_domain",
}
