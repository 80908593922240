import {Locale} from "../common/utils/Locale";

const isFrenchLocale = Locale.isFrench();

const sirdataGtmServerSidePricingUrl = isFrenchLocale
    ? "https://server-side.docs.sirdata.net/sirdata-server-side/tarifs/sirdata-server-side"
    : "https://server-side.docs.sirdata.net/sirdata-server-side/tarifs/sirdata-server-side";

const sirdataGtmServerSideProductUrl = isFrenchLocale
    ? "https://sirdata.com/fr/Server-Side"
    : "https://sirdata.com/fr/Server-Side";

const sirdataGtmServerSideDocumentationUrl = isFrenchLocale
    ? "https://server-side.docs.sirdata.net/sirdata-server-side/"
    : "https://server-side.docs.sirdata.net/sirdata-server-side/";

const sirdataGtmServerSideConfigureDnsUrl = isFrenchLocale
    ? "https://server-side.docs.sirdata.net/sirdata-server-side/installation/configuration-des-dns"
    : "https://server-side.docs.sirdata.net/sirdata-server-side/installation/configuration-des-dns";

export {
    sirdataGtmServerSidePricingUrl,
    sirdataGtmServerSideProductUrl,
    sirdataGtmServerSideDocumentationUrl,
    sirdataGtmServerSideConfigureDnsUrl
};

export function getRandomHost(): string {
    const CHARS = "abcdefghijklmnopqrstuvwxyz";
    const RANDOM_HOST_LENGTH = 6;
    let host: string = "";

    for (let i = 0; i < RANDOM_HOST_LENGTH; i++) {
        host = host + CHARS[Math.floor(Math.random() * CHARS.length)];
    }

    return host;
}

export function isValidDomain(domain: string): boolean {
    const regex = new RegExp("^[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,6}$", "i");

    return regex.test(domain);
}

export function getRandomPath(): string {
    const CHARS = "abcdefghijklmnopqrstuvwxyz";
    const LENGTHS = [6, 7, 8, 9];
    let randomPath: string = "";

    for (let i = 0; i < LENGTHS[Math.floor(Math.random() * LENGTHS.length)]; i++) {
        randomPath = randomPath + CHARS[Math.floor(Math.random() * CHARS.length)];
    }

    return `/${randomPath}`;
}

export const isBase64 = (value: string): boolean =>
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(value);
