import React, {useState} from "react";
import {Box, BoxRadius, BoxSpacing, Button, ButtonStyle, Color, Paragraph} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {IconTitle} from "..";
import {ModalHowConfigureDns} from "../../modal";
import {TranslationPortalFile} from "../../../utils/constants";

const CardDns: React.FC = () => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);
    const [activeHowConfigureDns, setActiveHowConfigureDns] = useState(false);

    return (
        <Box radius={BoxRadius.MD} spacing={BoxSpacing.LARGE} cssClass="card-dns">
            <div>
                <IconTitle icon={{name: "cloud", colorIcon: Color.PURPLE, outlined: true}} title={textContainer("card.dns.title")}/>
                <Paragraph withSpacing>
                    <span dangerouslySetInnerHTML={{__html: textContainer("card.dns.description")}}/>
                </Paragraph>
            </div>
            <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => setActiveHowConfigureDns(true)}>
                {textContainer("card.dns.action")}
            </Button>
            <ModalHowConfigureDns active={activeHowConfigureDns} onClose={() => setActiveHowConfigureDns(false)}/>
        </Box>
    );
};

export default CardDns;
