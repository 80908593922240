import numeral from "numeral";
import {ChartOptions} from "chart.js";
import {Formatter} from "../common/utils/Formatter";

export type GraphDataset = {
    label?: string;
    borderWidth?: number;
    borderColor?: string;
    backgroundColor: string | string[];
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointRadius?: number;
    data: number[];
};

export type GraphData = {
    labels: string[];
    datasets: GraphDataset[];
}

export const chartLineOptions: ChartOptions<"line"> = {
    animation: false,
    maintainAspectRatio: false,
    elements: {
        line: {
            tension: 0.4
        },
        point: {
            pointStyle: "rectRounded",
            hoverRadius: 10
        }
    },
    scales: {
        x: {
            type: "time",
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            grid: {
                display: false
            },
            border: {
                display: false
            }
        },
        y: {
            stacked: true,
            ticks: {
                callback: (tickValue) => Formatter.formatNumber(tickValue as number)
            },
            beginAtZero: true
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            mode: "index",
            position: "nearest",
            displayColors: true,
            callbacks: {
                title: (tooltipItem) => Formatter.formatDate(tooltipItem[0].label, "DD/MM/YYYY"),
                label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${numeral(tooltipItem.formattedValue).format("0,0").replaceAll(",", " ")}`
            }
        }
    }
};

export const CHART_DATASET_DEFAULT_STYLES = {
    borderWidth: 2,
    borderColor: "#16D2A585",
    backgroundColor: "#16D2A540",
    pointBackgroundColor: "#16D2A5",
    pointBorderColor: "#16D2A5",
    pointRadius: 4,
    fill: "origin"
};
