import clsx from "clsx";
import {FormLayoutColumns, FieldBlock, IconTooltip, InputText, FlexContentDirection, Box, BoxRadius, BoxSpacing, Icon} from "@sirdata/ui-lib";
import {Dispatch, FC, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {ContainerField} from "../../../api/model/container/ContainerField";
import {ContainerHostDetails} from "../../../api/model/container/ContainerHostDetails";
import {ContainerHostDetailsField} from "../../../api/model/container/ContainerHostDetailsField";
import {getRandomHost} from "../../../utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";

type FormContainerHostnameProps = {
    hostDetails: ContainerHostDetails;
    onChange: Dispatch<SetStateAction<ContainerHostDetails>>;
}

const FormContainerHostname: FC<FormContainerHostnameProps> = ({hostDetails, onChange}) => {
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <Box radius={BoxRadius.MD}>
            <FormLayoutColumns columns={2}>
                <FieldBlock
                    name={textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.SUBDOMAIN}`)}
                    label={textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.SUBDOMAIN}`)}
                    tooltip={textContainer("tooltip.host")}
                >
                    <IconTooltip
                        icon={{name: "rotate_right"}}
                        text={textContainer("action.generate_randomly")}
                        onClick={() => onChange((prevState) => new ContainerHostDetails({...prevState, [ContainerHostDetailsField.SUBDOMAIN]: getRandomHost()}))}
                    />
                    <InputText
                        placeholder={textContainer(`placeholder.${ContainerHostDetailsField.SUBDOMAIN}`)}
                        value={hostDetails.subdomain}
                        onChange={(value) => onChange((prevState) => new ContainerHostDetails({...prevState, [ContainerHostDetailsField.SUBDOMAIN]: value.trim().toLowerCase().replace(" ", "")}))}
                        required
                    />
                </FieldBlock>
                <FieldBlock
                    name={textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.DOMAIN}`)}
                    label={textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.DOMAIN}`)}
                    content={{direction: FlexContentDirection.COLUMN}}
                    tooltip={textContainer(`tooltip.${ContainerHostDetailsField.DOMAIN}`)}
                >
                    <InputText
                        placeholder={textContainer(`placeholder.${ContainerHostDetailsField.DOMAIN}`)}
                        value={hostDetails.domain}
                        onChange={(value) => onChange((prevState) => new ContainerHostDetails({...prevState, [ContainerHostDetailsField.DOMAIN]: value.trim().toLowerCase().replace(" ", "")}))}
                        required
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <Box radius={BoxRadius.MD} spacing={BoxSpacing.SMALL} cssClass={clsx("hostname-form", {"hostname-form--verified": hostDetails.hasValidHostname()})}>
                <div>
                    {"> "}
                    <span className={clsx({"hostname-form__result--highligthed": hostDetails.subdomain})}>{hostDetails.subdomain || textContainer(`placeholder.${ContainerHostDetailsField.SUBDOMAIN}`)}</span>
                    <span className={clsx({"hostname-form__result--highligthed": hostDetails.hasHostnameFilled()})}>.</span>
                    <span className={clsx({"hostname-form__result--highligthed": hostDetails.domain})}>{hostDetails.domain || textContainer(`placeholder.${ContainerHostDetailsField.DOMAIN}`)}</span>
                </div>
                <Icon name="check_circle" outlined/>
            </Box>
        </Box>
    );
};

export default FormContainerHostname;
