import {ApiModel} from "../../../common/api/model/ApiModel";
import {CmpConfigStatus} from "./CmpConfigStatus";

export class CmpConfig extends ApiModel {
    id: string = "";
    id_partner: number = 0;
    name: string = "";
    domain: string = "";
    status: CmpConfigStatus = CmpConfigStatus.INACTIVE;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
