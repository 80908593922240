import {ApiClient} from "./ApiClient";
import {CommonApiSession} from "../common/api/CommonApiSession";
import {RestCmpConfig} from "./rest/RestCmpConfig";
import {RestContainer} from "./rest/RestContainer";
import {RestHelperDomain} from "./rest/RestHelperDomain";
import {RestStat} from "./rest/RestStat";

export class ApiSession extends CommonApiSession {
    _client: ApiClient;

    private _restCmp: RestCmpConfig | undefined;
    private _restContainer: RestContainer | undefined;
    private _restHelperDomain: RestHelperDomain | undefined;
    private _restStat: RestStat | undefined;

    constructor() {
        super();
        this._client = new ApiClient();
    }

    get restCmp(): RestCmpConfig {
        return this._restCmp !== undefined ? this._restCmp : this._restCmp = new RestCmpConfig(this._client);
    }

    get restContainer(): RestContainer {
        return this._restContainer !== undefined ? this._restContainer : this._restContainer = new RestContainer(this._client);
    }

    get restHelperDomain(): RestHelperDomain {
        return this._restHelperDomain !== undefined ? this._restHelperDomain : this._restHelperDomain = new RestHelperDomain(this._client);
    }

    get restStat(): RestStat {
        return this._restStat !== undefined ? this._restStat : this._restStat = new RestStat(this._client);
    }
}

export const session = new ApiSession();
