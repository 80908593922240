import clsx from "clsx";
import {
    Action,
    AlertSeverity,
    Button,
    ButtonSize,
    ButtonStyle,
    Color,
    ContentBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    IconProps,
    IconTooltip,
    ModalConfirmMessage,
    Table,
    TableActionColumn,
    TableColumn,
    TableColumnStyle,
    TableRow,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {CardDeploy, CardDns} from "../../component/snippet";
import {Container} from "../../api/model/container/Container";
import {ContainerErrorCode} from "../../api/model/container/ContainerErrorCode";
import {ContainerField} from "../../api/model/container/ContainerField";
import {ContainerHostDetailsField} from "../../api/model/container/ContainerHostDetailsField";
import {ContainerHostDetailsStatus} from "../../api/model/container/ContainerHostDetailsStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {ModalAddContainerHostname} from "../../component/modal";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

enum ContainerDetailsModal {
    ADD_HOSTNAME,
    DELETE_HOSTNAME
}

type ContainerDetailsConfigurationProps = {
    container: Container;
    onEdit: (container: Container) => void;
}

const ContainerDetailsConfiguration: FC<ContainerDetailsConfigurationProps> = ({container: initContainer, onEdit}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    const [container, setContainer] = useState<Container>(new Container());
    const [activeHostname, setActiveHostname] = useState<number>();
    const [activeModal, setActiveModal] = useState<ContainerDetailsModal>();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setContainer(initContainer);
    }, [initContainer]);

    const handleRefreshHost = () => {
        if (refresh) return;

        setRefresh(true);
        setTimeout(async () => {
            try {
                const newContainer = await session.restContainer.get(initContainer.id);
                onEdit(newContainer);
            } catch(e) {
            } finally {
                setActiveHostname(undefined);
                setRefresh(false);
            }
        }, 1000);
    };

    const handleDeleteHostname = async () => {
        if (activeHostname === undefined) return;

        try {
            const newContainer = new Container(container);
            newContainer.instance_hosts.splice(activeHostname, 1);
            const updatedContainer = await session.restContainer.update(newContainer);
            UIEventManager.alert({text: textContainer("success.update"), severity: AlertSeverity.SUCCESS, isClosable: true});
            onEdit(updatedContainer);
        } catch(e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_UPDATE_CONF}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        } finally {
            setActiveHostname(undefined);
            setActiveModal(undefined);
        }
    };

    const getStatusIcon = (status: ContainerHostDetailsStatus): IconProps => {
        switch (status) {
            case ContainerHostDetailsStatus.OK:
                return {name: "check_circle", colorIcon: Color.GREEN, outlined: true};
            case ContainerHostDetailsStatus.MISCONFIGURED:
                return {name: "cancel", colorIcon: Color.CORAL, outlined: true};
            default:
                return {name: "schedule", colorIcon: Color.ORANGE, outlined: true};
        }
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            alignment={FlexContentAlignment.START}
            layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}
            spacing={FlexContentSpacing.MEDIUM}
            mobileDirection={FlexContentMobileDirection.COLUMN}
        >
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} cssClass="container-details-configuration">
                <ContentBlock
                    header={{
                        actions: [
                            <Button
                                key="add-hostname-button"
                                size={ButtonSize.SMALL}
                                style={ButtonStyle.PRIMARY_WHITE}
                                icon={Action.ADD.icon}
                                onClick={() => setActiveModal(ContainerDetailsModal.ADD_HOSTNAME)}
                                cssClass="add-hostname-button"
                            >
                                {textCommon(Action.ADD.labelKey)}
                            </Button>
                        ],
                        title: {
                            label: textContainer("hostname_list")
                        }
                    }}
                >
                    <Table
                        columns={[
                            {label: textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.STATUS}`), width: 15, styles: TableColumnStyle.ALIGN_CENTER},
                            {label: textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.SUBDOMAIN}`), width: 15},
                            {label: textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.FQDN}`), width: 60},
                            {label: undefined, width: 10}
                        ]}
                    >
                        {container.host_details.map((host, index) =>
                            <TableRow key={host.fqdn}>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                    <IconTooltip text={textContainer(`status.${host.status}`)} icon={getStatusIcon(host.status)}/>
                                </TableColumn>
                                <TableColumn>{host.subdomain}</TableColumn>
                                <TableColumn>{host.fqdn}</TableColumn>
                                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                    {host.status !== ContainerHostDetailsStatus.OK && (
                                        <IconTooltip
                                            text={textContainer("action.refresh")}
                                            icon={{name: "refresh"}}
                                            onClick={() => {
                                                setActiveHostname(index);
                                                handleRefreshHost();
                                            }}
                                            cssClass={clsx("refresh-icon", {"refresh-icon--animate": (refresh && activeHostname === index)})}
                                        />
                                    )}
                                    {container.host_details.length > 1 && (
                                        <IconTooltip
                                            text={textCommon(Action.DELETE.labelKey)}
                                            icon={{...Action.DELETE.icon, colorIcon: ""}}
                                            onClick={() => {
                                                setActiveHostname(index);
                                                setActiveModal(ContainerDetailsModal.DELETE_HOSTNAME);
                                            }}
                                        />
                                    )}
                                </TableActionColumn>
                            </TableRow>
                        )}
                    </Table>
                </ContentBlock>
            </FlexContent>
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} mobileDirection={FlexContentMobileDirection.ROW}>
                <CardDns/>
                <CardDeploy/>
            </FlexContent>
            <ModalAddContainerHostname
                active={activeModal === ContainerDetailsModal.ADD_HOSTNAME}
                container={container}
                onEdit={onEdit}
                onClose={() => setActiveModal(undefined)}
            />
            <ModalConfirmMessage
                active={activeModal === ContainerDetailsModal.DELETE_HOSTNAME}
                message={textContainer("modal.delete.hostname")}
                cancel={{onClick: () => setActiveModal(undefined), style: ButtonStyle.PRIMARY_MIDNIGHT, children: textCommon(Action.CANCEL.labelKey)}}
                confirm={{onClick: handleDeleteHostname, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(Action.DELETE.labelKey)}}
            />
        </FlexContent>
    );
};

export default ContainerDetailsConfiguration;
