import {useEffect, useRef, useState} from "react";

export const useDebounce = <T = unknown>(value: T, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    let timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        timeoutRef.current = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [value, delay]);

    return debouncedValue;
};
