import {Rest} from "../../common/api/rest/Rest";
import {HelperDomainSearchResult} from "../model/analytics-helper/HelperDomainSearchResult";

export class RestHelperDomain extends Rest {
    rootPath = "/gtm-helper-api/analytics-helper/conf";

    search(): Promise<HelperDomainSearchResult> {
        return this._client.get(this.rootPath, HelperDomainSearchResult) as Promise<HelperDomainSearchResult>;
    }
}
