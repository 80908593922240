import {ApiModel} from "../../../common/api/model/ApiModel";
import {HelperDomainStatus} from "./HelperDomainStatus";

export class HelperDomain extends ApiModel {
    id: number = 0;
    name: string = "";
    domain: string = "";
    status: HelperDomainStatus = HelperDomainStatus.INACTIVE;
    partner_id: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
