import {Rest} from "../../common/api/rest/Rest";
import {Container} from "../model/container/Container";
import {ContainerStatus} from "../model/container/ContainerStatus";
import {ContainerSearchQuery} from "../model/container/search/ContainerSearchQuery";
import {ContainerSearchResult} from "../model/container/search/ContainerSearchResult";

export class RestContainer extends Rest {
    rootPath = "/gtm-helper-api/conf";

    search(query: ContainerSearchQuery): Promise<ContainerSearchResult> {
        return this._client.get(this.rootPath, ContainerSearchResult, {queryParams: query}) as Promise<ContainerSearchResult>;
    }

    get(containerId: number): Promise<Container> {
        return this._client.get(`${this.rootPath}/${containerId}`, Container) as Promise<Container>;
    }

    create(container: Container): Promise<Container> {
        return this._client.post(this.rootPath, container, Container) as Promise<Container>;
    }

    update(container: Container): Promise<Container> {
        return this._client.put(`${this.rootPath}/${container.id}`, container, Container) as Promise<Container>;
    }

    updateStatus(containerId: number, status: ContainerStatus): Promise<Container> {
        return this._client.patch(`${this.rootPath}/${containerId}/status`, {status}, Container) as Promise<Container>;
    }

    delete(containerId: number) {
        return this._client.delete(`${this.rootPath}/${containerId}`);
    }
}
