import {
    ButtonLink,
    ButtonLinkStyle,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    LayoutRows,
    Loadable,
    Tabs
} from "@sirdata/ui-lib";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import ContainerDetailsAnalytics from "./ContainerDetailsAnalytics";
import ContainerDetailsConfiguration from "./ContainerDetailsConfiguration";
import ContainerDetailsConfigurationAdvanced from "./ContainerDetailsConfigurationAdvanced";
import ContainerDetailsHeader from "./ContainerDetailsHeader";
import {Container} from "../../api/model/container/Container";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {session} from "../../api/ApiSession";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {TranslationPortalFile, pathContainerList} from "../../utils/constants";

function ContainerDetails() {
    const navigate = useNavigate();
    const {id: containerId} = useParams() as {id: string};
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    const [container, setContainer] = useState<Container>(new Container());

    useEffect(() => {
        (async function () {
            try {
                const container = await session.restContainer.get(+containerId);
                setContainer(container);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            }
        })();
    }, [containerId]);

    const handleEditContainer = (container: Container) => setContainer(container);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent cssClass="container-details">
                <Loadable loading={!container.id}>
                    <LayoutRows>
                        <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.SMALL}>
                            <ButtonLink
                                style={ButtonLinkStyle.GREY}
                                icon={{name: "chevron_left"}}
                                reverse
                                reverseUnderline
                                onClick={() => navigate(pathContainerList)}
                            >
                                {textContainer("action.back_to_list")}
                            </ButtonLink>
                            <ContainerDetailsHeader container={container} onEdit={handleEditContainer}/>
                        </FlexContent>
                        <Tabs
                            headers={[
                                {label: textContainer("tabs.configuration")},
                                {label: textContainer("tabs.advanced_configuration")},
                                {label: textContainer("tabs.analytics")}
                            ]}
                        >
                            <ContainerDetailsConfiguration container={container} onEdit={handleEditContainer}/>
                            <ContainerDetailsConfigurationAdvanced container={container} onEdit={handleEditContainer}/>
                            <ContainerDetailsAnalytics container={container}/>
                        </Tabs>
                    </LayoutRows>
                </Loadable>
            </MainContent>
        </Wrapper>
    );
}

export default ContainerDetails;
