import {ApiModel} from "../../../common/api/model/ApiModel";
import {HelperDomain} from "./HelperDomain";

export class HelperDomainSearchResult extends ApiModel {
    elements: HelperDomain[] = [];
    page: number = 0;
    size: number = 0;
    total_elements: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "elements") {
                    (o[key] as HelperDomain[]).forEach((obj) => {
                        const it = new HelperDomain(obj);
                        this.elements.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }
}
