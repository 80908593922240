export enum ContainerSortOrder {
    DESCENDANT = "DESCENDANT",
    ASCENDANT = "ASCENDANT"
}

export type SortOrderItem = {
    type: ContainerSortOrder;
    field: string;
}

export const CONTAINER_SORT_ORDERS: SortOrderItem[] = [
    {type: ContainerSortOrder.DESCENDANT, field: "desc"},
    {type: ContainerSortOrder.ASCENDANT, field: "asc"}
];

export const defaultSortOrderItem = CONTAINER_SORT_ORDERS[0];
