import {ApiModel} from "../../../common/api/model/ApiModel";
import {ContainerOverridePropertiesPerHostListField} from "./ContainerOverridePropertiesPerHostListField";
import {ContainerProperties} from "./ContainerProperties";

export class ContainerOverridePropertiesPerHostList extends ApiModel {
    id: number = 0;
    hosts: string[] = [];
    properties: ContainerProperties = new ContainerProperties();
    active: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === ContainerOverridePropertiesPerHostListField.PROPERTIES) {
                    this[key] = new ContainerProperties(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            hosts: this.hosts,
            properties: this.properties.getJsonParameters(),
            active: this.active
        };
    }
}
