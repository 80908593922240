import React, {FunctionComponent, Suspense, useEffect, useState} from "react";
import {Route, BrowserRouter, Routes, Navigate} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";
import "./utils/ChartJS";

import PrivateRoute from "./component/guard/PrivateRoute";
import {Access, ContainerCreate, ContainerDetails, ContainerList, Home, LegalNotice, Login, Sso, TermsAndConditions} from "./screen/index";
import {Error403, Error404, Error500} from "./common/screen/index";
import {ApiEventListener} from "./common/component/snippet";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import {pathContainerCreate, pathContainerDetails, pathContainerList, pathTermsAndConditions} from "./utils/constants";
import {PortalContextProvider} from "./common/context/PortalContext";
import {PortalSetting} from "./common/api/model/portal-setting/PortalSetting";
import {session} from "./api/ApiSession";

const App: FunctionComponent = () => {
    const [portalSetting, setPortalSetting] = useState<PortalSetting>(new PortalSetting());

    useEffect(() => {
        (async () => {
            try {
                setPortalSetting(await session.getPortalSetting());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <PortalContextProvider portalSetting={portalSetting}>
                    <BrowserRouter>
                        <ApiEventListener/>
                        <ScrollToTop/>
                        <Routes>
                            <Route path={pathSso} element={<Sso/>}/>

                            <Route path={pathAccess} element={<Access/>}/>
                            <Route path={pathError403} element={<Error403/>}/>
                            <Route path={pathError404} element={<Error404/>}/>
                            <Route path={pathError500} element={<Error500/>}/>

                            <Route path="/" element={<Navigate to={pathLogin}/>}/>
                            <Route path={pathLogin} element={<Login/>}/>

                            <Route path={pathHome} element={<PrivateRoute children={<Home/>}/>}/>
                            <Route path={pathContainerCreate} element={<PrivateRoute children={<ContainerCreate/>}/>}/>
                            <Route path={pathContainerDetails} element={<PrivateRoute children={<ContainerDetails/>}/>}/>
                            <Route path={pathContainerList} element={<PrivateRoute children={<ContainerList/>}/>}/>

                            <Route path={pathLegalNotice} element={<LegalNotice/>}/>
                            <Route path={pathTermsAndConditions} element={<TermsAndConditions/>}/>

                            <Route path="*" element={<Error404/>}/>
                        </Routes>
                    </BrowserRouter>
                </PortalContextProvider>
            </Suspense>
        </React.StrictMode>
    );
};

export default App;
